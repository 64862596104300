<ng-container *ngIf="!eventStarted; else eventHasStarted">
  <div *ngIf="(timeLeft$ | async) as t; else loader" class="counter mt-2 py-4">
    <div class="d-flex flex-row justify-content-center justify-content-sm-start gap-3 gap-sm-4 gap-lg-5">
      <div class="text-center">
        <h1 id="days">{{t.daysToDday | number : '3.0'}} </h1>
        <p id="daystxt">Days</p>
      </div>
      <div class="text-center">
        <h1 id="hours">{{t.hoursToDday | number : '2.0'}}</h1>
        <p id="hourstxt">Hours</p>
      </div>
      <div class="text-center">
        <h1 id="minutes">{{t.minutesToDday | number : '2.0'}}</h1>
        <p id="minutestxt">Minutes</p>
      </div>
      <div class="text-center">
        <h1 id="seconds">{{t.secondsToDday | number : '2.0'}}</h1>
        <p id="secondstxt">Seconds</p>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loader>
  <div class="counter mt-2 py-4">
    <div class="d-flex flex-row justify-content-center justify-content-sm-start gap-3 gap-sm-4 gap-lg-5">
      <div class="text-center">
        <h1 id="days">000</h1>
        <p id="daystxt">Days</p>
      </div>
      <div class="text-center">
        <h1 id="hours">00</h1>
        <p id="hourstxt">Hours</p>
      </div>
      <div class="text-center">
        <h1 id="minutes">00</h1>
        <p id="minutestxt">Minutes</p>
      </div>
      <div class="text-center">
        <h1 id="seconds">00</h1>
        <p id="secondstxt">Seconds</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #eventHasStarted>
  <div class="counter mt-2 py-4">
    <div class="d-flex flex-row justify-content-center justify-content-sm-start">
      <div class="text-center">
        <h1 id="days">Event has started</h1>
      </div>
    </div>
  </div>
</ng-template>