<!-- Navigation -->
<nav class="navbar navbar-expand-xl navbar-dark sticky-top">
  <div class="container">
    <a class="navbar-brand" (click)="closeMobileNav()" routerLink="/">
      <img src="assets/satnac_2024_logo.svg" alt="SATNAC Logo" height="30">
    </a>
    <button class="navbar-toggler mobilebtn" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0 text-center">
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" aria-current="page"
            [ngClass]="{ active: home.isActive, 'text-white': !home.isActive }" [routerLink]="['/']" routerLinkActive
            #home="routerLinkActive" [routerLinkActiveOptions]="{ exact: true }" [routerLink]="['/']"
            routerLinkActive="active">HOME</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/about-satnac']" [ngClass]="{
              active: about.isActive,
              'text-white': !about.isActive
            }" routerLinkActive #about="routerLinkActive">ABOUT</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/programme']" [ngClass]="{
              active: programme.isActive, 'text-white': !programme.isActive
            }"
            routerLinkActive
            #programme="routerLinkActive"
          >
            PROGRAMME
          </a>
        </li> -->
        <li class="nav-item dropdown">
          <a 
            class="nav-link dropdown-toggle" 
            href="#" 
            id="registrationDropdown" 
            role="button" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            [ngClass]="{ active: register.isActive, 'text-white': !register.isActive }"
          >
            REGISTRATION
          </a>
          <ul class="dropdown-menu" aria-labelledby="registrationDropdown">
            <li>
              <a class="dropdown-item" (click)="closeMobileNav()" [routerLink]="['/registration-booking-accommodation']" routerLinkActive #register="routerLinkActive">
                Booking and Accommodation
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="closeMobileNav()" [routerLink]="['/registration-events-and-travel']" routerLinkActive #register="routerLinkActive">
                Events and Travel Information
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/call-for-papers']"
            [ngClass]="{ active: call.isActive, 'text-white': !call.isActive }" routerLinkActive
            #call="routerLinkActive">CALL FOR PAPERS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/proceedings']"
            [ngClass]="{ active: proceedings.isActive, 'text-white': !proceedings.isActive }" routerLinkActive
            #proceedings="routerLinkActive">PROCEEDINGS</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/sponsorship']" [ngClass]="{
              active: sponser.isActive,
              'text-white': !sponser.isActive
            }" routerLinkActive #sponser="routerLinkActive">SPONSORSHIP</a>
        </li>
        <li class="nav-item dropdown">
          <a 
            class="nav-link dropdown-toggle" 
            href="#" 
            id="challengeDropdown" 
            role="button" 
            data-bs-toggle="dropdown" 
            aria-expanded="false"
            [ngClass]="{ active: challenge.isActive, 'text-white': !challenge.isActive }"
          >
            CHALLENGE
          </a>
          <ul class="dropdown-menu" aria-labelledby="challengeDropdown">
            <li>
              <a class="dropdown-item" (click)="closeMobileNav()" [routerLink]="['/challenge']" routerLinkActive #challenge="routerLinkActive">
                Current Challenge (2024)
              </a>
            </li>
            <li>
              <a class="dropdown-item" (click)="closeMobileNav()" [routerLink]="['/past-challenge-2023']" routerLinkActive #challenge="routerLinkActive">
                Past Challenge (2023)
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="closeMobileNav()" [routerLink]="['/contact']"
            [ngClass]="{ active: rla.isActive, 'text-white': !rla.isActive }" routerLinkActive
            #rla="routerLinkActive">CONTACT</a>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- End of Navigation -->