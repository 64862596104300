import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface timeComponents {
  secondsToDday: number;
  minutesToDday: number;
  hoursToDday: number;
  daysToDday: number;
}

@Component({
  selector: 'app-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
})
export class CountdownComponent implements OnInit {
  timeLeft$: Observable<timeComponents> | undefined;
  _isBrowser: boolean;
  eventStarted = false;
  eventDay = new Date('Oct 6, 2024 07:00:00');

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    this._isBrowser = isPlatformBrowser(platformId);
    if (this._isBrowser) {
      this.timeLeft$ = interval(1000).pipe(
        map((x) => this.calcDateDiff(this.eventDay)),
        shareReplay(1)
      );
    }
  }

  ngOnInit(): void {
    this.calcEventStarted(this.eventDay);
  }

  calcEventStarted(endDay: Date) {
    const currentDate = new Date();
    const timeDifference = endDay.getTime() - currentDate.getTime();

    if (timeDifference < 0) {      
      this.eventStarted = true;
    } else {
      this.eventStarted = false;
    }
  }

  calcDateDiff(endDay: Date): timeComponents {
    const dDay = endDay.valueOf();

    const milliSecondsInASecond = 1000;
    const hoursInADay = 24;
    const minutesInAnHour = 60;
    const secondsInAMinute = 60;

    const timeDifference = dDay - Date.now();

    const daysToDday = Math.floor(
      timeDifference /
        (milliSecondsInASecond *
          minutesInAnHour *
          secondsInAMinute *
          hoursInADay)
    );

    const hoursToDday = Math.floor(
      (timeDifference /
        (milliSecondsInASecond * minutesInAnHour * secondsInAMinute)) %
        hoursInADay
    );

    const minutesToDday = Math.floor(
      (timeDifference / (milliSecondsInASecond * minutesInAnHour)) %
        secondsInAMinute
    );

    const secondsToDday =
      Math.floor(timeDifference / milliSecondsInASecond) % secondsInAMinute;

    return { secondsToDday, minutesToDday, hoursToDday, daysToDday };
  }
}
