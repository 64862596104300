<div class="homeHeroBanner">
  <div class="homeHeroBannerGradient py-5 d-flex flex-column min-vh-100">
    <div class="container-fluid mt-sm-auto mb-sm-auto">
      <div class="container text-white mt-lg-5 pt-sm-5">
        <div class="row align-items-center theme-title">
          <div class="col-12 col-md-9 col-xl-10">
            <div
              class="d-flex align-items-center justify-content-center justify-content-sm-start mb-2 text-center text-sm-start">
              <p class="fs-6 fw-light flex-md-shrink-0 mb-0">
                <strong>SATNAC</strong> 2024 | 6 - 9 October 
                <span class="d-none d-sm-inline">| </span>
                <span class="d-block d-sm-inline mt-1 mt-sm-0">Skukuza, Kruger National Park</span>
              </p>
              <div class="white-bar d-none d-sm-block ms-sm-3"></div>
            </div>
            <h1 class="text-center text-sm-start lh-sm mb-0">The AI-Driven Hyperconnected Future</h1>
            <div
              class="d-flex align-items-center justify-content-center justify-content-sm-start mt-2 text-center text-sm-start">
              <div class="white-bar d-none d-sm-block me-sm-3"></div>
              <p class="fs-5 fw-normal flex-shrink-0 mb-1">co-creating shared value</p>
            </div>
          </div>
        </div>

        <app-countdown *ngIf="_isBrowser"></app-countdown>

        <div class="text-center text-sm-start mt-3 mb-4">
          <a [href]="registrationLink" role="button" class="btn btn-primary px-5">REGISTER NOW</a>
        </div>
      </div>
    </div>

    <!-- Mouse scroll icon -->
    <div class="container mt-4 mt-md-auto mb-md-4 d-none d-md-block">
      <div class="row">
        <div class="col-12">
          <div class="scroll-msg-inner">
            <div class="scroll-msg-wheel">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="particle-container">
    <ng-particles [id]="id" [options]="particlesOptions" [particlesInit]="particlesInit"
      (particlesLoaded)="particlesLoaded($event)"></ng-particles>
  </div>
</div>

<!-- Africa's leading ICT conference section -->
<div class="container-fluid homeHeaderGradient text-white py-5" [ngClass]="sponsorshipData && sponsorshipData.length > 0 ? '' : 'withoutSponsors'">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-lg-6 col-xl-5 mb-3 mb-lg-0">
        <img srcset="assets/home/2022/africa_leading_ict.png, assets/home/2022/africa_leading_ict@2x.png 2x"
          src="assets/home/2022/africa_leading_ict.png" alt="Lady making a speech" class="img-fluid" role="presentation"
          aria-hidden="true">
      </div>
      <div class="col-12 col-lg-6 col-xl-7 pt-3 pt-lg-0 text-center text-sm-start">
        <h3 class="fw-light mb-3">
          Africa's leading <strong>ICT conference</strong>
        </h3>
        <p>The two-day SATNAC conference features a number of plenary sessions and presentations focusing on the
          theme: <strong>The AI-Driven Hyperconnected Future: co-creating shared value</strong>, with
          speakers and panelists representing local and international telecommunication, software, network and
          infrastructure providers.</p>
        <!-- <p>Follow the live stream on: <a class="text-white"
            href="https://www.youtube.com/live/qs-1eY9Hm7w?si=jP2hbf4D-AWsuNuH"
            target="_blank">https://www.youtube.com/live/qs-1eY9Hm7w?si=jP2hbf4D-AWsuNuH</a></p> -->
        <a [routerLink]="['/about-satnac']" role="button" class="btn btn-primary mt-2">LEARN MORE ABOUT SATNAC</a>
      </div>
    </div>
  </div>

  <ng-container *ngIf="sponsorshipData && sponsorshipData.length > 0">
    <div class="container mt-5">
      <ng-container *ngIf="diamondSponsors && diamondSponsors.length > 0">
        <div class="row pt-5">
          <div class="col-12 text-center dark-grey">
            <h4 class="fw-light mb-3">
              <strong><i class="bi bi-gem me-2"></i>Diamond</strong> Sponsors
            </h4>
          </div>
        </div>

        <div class="row pt-4 pb-2 justify-content-center align-items-center">
          <div class="col-6 col-sm-4 col-lg-2 mb-3 text-center" *ngFor="let sponsor of diamondSponsors">
            <a [href]="sponsor.website" target="_blank" [attr.aria-label]="'Open ' + sponsor.name + ' website'">
              <img [src]="'assets/sponsorship/logos/' + sponsor.logo" [alt]="sponsor.name + ' Logo'" height="75">
            </a>
          </div>
        </div>        
      </ng-container>

      <ng-container *ngIf="platinumSponsors && platinumSponsors.length > 0">
        <hr class="dark-grey" />

        <div class="row pt-5">
          <div class="col-12 text-center dark-grey">
            <h4 class="fw-light mb-3 text-info"><strong>Platinum</strong> Sponsors</h4>
          </div>
        </div>

        <div class="row pt-4 pb-2 justify-content-center align-items-center">
          <div class="col-6 col-sm-4 col-lg-2 mb-3 text-center" *ngFor="let sponsor of platinumSponsors">
            <a [href]="sponsor.website" target="_blank" [attr.aria-label]="'Open ' + sponsor.name + ' website'">
              <img [src]="'assets/sponsorship/logos/' + sponsor.logo" [alt]="sponsor.name + ' Logo'" height="75">
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="insightsSponsors && insightsSponsors.length > 0">
        <hr class="dark-grey" />

        <div class="row pt-2">
          <div class="col-12 text-center pb-50">
            <h4 class="fw-light mb-3 text-darker-blue"><strong>Insights</strong> Sponsors</h4>
          </div>
        </div>

        <div class="row pb-2 justify-content-center">
          <div class="col-6 col-sm-4 col-lg-2 mb-3 text-center" *ngFor="let sponsor of insightsSponsors">
            <a [href]="sponsor.website" target="_blank" [attr.aria-label]="'Open ' + sponsor.name + ' website'">
              <img [src]="'assets/sponsorship/logos/' + sponsor.logo" [alt]="sponsor.name + ' Logo'" height="75">
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="goldSponsors && goldSponsors.length > 0">
        <hr class="dark-grey" />

        <div class="row pt-2">
          <div class="col-12 text-center pb-50">
            <h4 class="fw-light mb-3 gold"><strong>Gold</strong> Sponsors</h4>
          </div>
        </div>

        <div class="row pb-2 justify-content-center">
          <div class="col-6 col-sm-4 col-lg-2 mb-3 text-center" *ngFor="let sponsor of goldSponsors">
            <a [href]="sponsor.website" target="_blank" [attr.aria-label]="'Open ' + sponsor.name + ' website'">
              <img [src]="'assets/sponsorship/logos/' + sponsor.logo" [alt]="sponsor.name + ' Logo'" height="75">
            </a>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="silverSponsors && silverSponsors.length > 0">
        <hr class="dark-grey" />

        <div class="row pt-2 silver">
          <div class="col-12 text-center pb-50">
            <h4 class="fw-light mb-3"><strong>Silver</strong> Sponsors</h4>
          </div>
        </div>

        <div class="row pb-5 justify-content-center">
          <div class="col-6 col-sm-4 col-lg-2 mb-3 text-center" *ngFor="let sponsor of silverSponsors">
            <a [href]="sponsor.website" target="_blank" [attr.aria-label]="'Open ' + sponsor.name + ' website'">
              <img [src]="'assets/sponsorship/logos/' + sponsor.logo" [alt]="sponsor.name + ' Logo'" height="75">
            </a>
          </div>
        </div>
      </ng-container>

      <div class="row mt-3">
        <div class="col-12 text-center pb-3">
          <a routerLink="/sponsorship" role="button" class="btn btn-primary">MORE ABOUT SPONSORSHIP</a>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- SATNAC Offers -->
<div class="container-fluid pt-5 pb-5 unprecedentedValue">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-6 col-xl-5 align-self-center mb-3 mb-lg-0">
        <app-video-player video="https://www.youtube.com/embed/1_RuQ2szQJs" title="SATNAC 2023 - Highlights"></app-video-player>
      </div>

      <div class="col-12 col-lg-6 col-xl-7 align-self-center text-center text-sm-start">
        <h3 class="fw-light mb-3 text-primary">
          SATNAC Offers <strong>Unprecedented Value</strong>
        </h3>
        <p class="fw-light">
          SATNAC always provides a schedule filled with noteworthy, valuable,
          and memorable events. Check out the schedule page for an overview of
          the upcoming events, which includes presentations, panels, dinners,
          and a golf day.
        </p>
        <!-- <a routerLink="/programme" role="button" class="btn btn-primary mt-2">VIEW PROGRAMME</a> -->
      </div>
    </div>
  </div>
</div>

<!-- Quote -->
<div class="bg-dark py-5 quotesBackgroundStars d-flex align-items-center">
  <div class="container">
    <div id="carouselQuotes" class="carousel carousel-dark h-100" data-bs-ride="carousel">
      <div class="carousel-inner text-center px-5 d-flex align-items-center">
        <div class="carousel-item active h-100" data-bs-interval="5000">
          <h5 class="text-white">
            “The SATNAC conference provides a valuable platform for staff and
            students to be exposed to the latest trends and development in the
            ICT sector, both locally and internationally.”
          </h5>
          <p class="small mt-3 mb-0 text-white">
            — Prof Anish Kurien<br />Node Director: F’SATI TUT <br />
            Section Head: Electrical Engineering (PG, RnI)<br />
            Professor: Electrical Engineering
          </p>
        </div>
        <div class="carousel-item h-100" data-bs-interval="5000">
          <h5 class="text-white">
            “SATNAC is a unique conference that brings together students,
            academia, and leaders from industry under one roof. Many delegates
            are frequent attendees to this annual conference and have become
            part of the SATNAC family.”
          </h5>
          <p class="small mt-3 mb-0 text-white">
            — Gys Booysen<br />Former LOC Member<br />and Secretary
          </p>
        </div>
        <div class="carousel-item h-100" data-bs-interval="5000">
          <h5 class="text-white">
            “The SATNAC conference is a prestigious international technology
            event that has played a significant role in showcasing the
            intellectual capability building efforts in Southern Africa over the
            past 24 years.”
          </h5>
          <p class="small mt-3 mb-0 text-white">
            — Marius Mostert<br />Chairman & Chief Technical Officer<br />MCORP
            Communications
          </p>
        </div>
        <div class="carousel-item h-100" data-bs-interval="5000">
          <h5 class="text-white">
            “SATNAC is an excellent event that allows Telkom CoE groups and
            industrial partners to exchange within a common platform on the
            latest advancement in telecommunications.”
          </h5>
          <p class="small mt-3 mb-0 text-white">
            — Prof Karim Djouani<br />NRF SARChI Chair in Enabled Environment
            and Assistive living
          </p>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselQuotes" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselQuotes" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>
  </div>
</div>

<!-- Speakers -->
<ng-container *ngIf="speakerProfiles && speakerProfiles.length > 0">
  <div class="container-fluid homeVenueBannerGradient">
    <div class="container">
      <div class="row pt-5">
        <div class="col-12 text-center pb-3">
          <h3 class="fw-light text-dark">Our <strong>Speakers</strong></h3>
        </div>
      </div>

      <div class="row g-3 text-sm-start text-center justify-content-center">
        <div class="col-11 col-sm-6 col-md-5 col-lg-auto" *ngFor="let speaker of speakerProfiles">
          <div class="portraitCardWrapper">
            <img src="{{ speaker.profileImage }}" alt="Photo of {{ speaker.name + ' ' + speaker.surname }}"
              class="speaker-photo" />

            <div class="portraitCardContainer">
              <div class="text-white portraitCard">
                <h5 class="fw-light mb-1">{{ speaker.name }} <strong>{{ speaker.surname }}</strong></h5>
                <p class="accentBlue" [ngClass]="{ 'mb-1' : speaker.company }">{{ speaker.position }}</p>
                <p *ngIf="speaker.company">{{ speaker.company }}</p>
              </div>
            </div>

            <div class="speakerBioContainer d-flex align-items-center text-center">
              <p class="mb-0">{{ speaker.bio }}</p>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ng-container>

<div class="container-fluid homeVenueBanner">
  <div class="container text-white">
    <div class="row">
      <div class="col-10 col-lg-8 col-xl-7 col-xxl-6 text-start">
        <h3 class="text-dark fw-light mb-3">A venue that <strong>walks on the wild side</strong></h3>
        <p class="text-dark h6">
          SATNAC is proud to host its attendees and speakers at <strong>Skukuza Safari Lodge</strong>, located within the renowned <strong>Skukuza Rest Camp in Kruger National Park</strong>. The location is your gateway to the ultimate bush escape,
          where you can immerse yourself in the untamed beauty of the African wilderness while enjoying luxurious
          comforts and unrivalled biodiversity.</p>
          <a routerLink="/registration-booking-accommodation" role="button" class="btn btn-primary mt-4 px-5">VIEW BOOKING DETAILS</a>
      </div>
    </div>
  </div>
</div>