import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'about-satnac',
    data: { title: 'SATNAC | About SATNAC' },
    loadChildren: () =>
      import('./page/page-about/page-about.module').then(
        (m) => m.PageAboutModule
      ),
  },
  {
    path: 'Home',
    data: { title: 'SATNAC | Home' },
    loadChildren: () =>
      import('./page/page-home/page-home.module').then((m) => m.PageHomeModule),
  },
  // {
  //   path: 'programme',
  //   data: { title: 'SATNAC | Programme' },
  //   loadChildren: () =>
  //     import('./page/page-programme/page-programme.module').then(
  //       (m) => m.PageProgrammeModule
  //     ),
  // },
  {
    path: 'registration-booking-accommodation',
    data: { title: 'SATNAC | Registration - Registration - Booking and Accommodation' },
    loadChildren: () =>
      import('./page/page-registration-booking-accommodation/page-registration-booking-accommodation.module').then(
        (m) => m.PageRegistrationBookingAccommodationModule
      ),
  },
  {
    path: 'registration-events-and-travel',
    data: { title: 'SATNAC | Registration - Events and Travel Information' },
    loadChildren: () =>
      import('./page/page-registration-events-and-travel/page-registration-events-and-travel.module').then(
        (m) => m.PageRegistrationEventsAndTravelModule
      ),
  },
  {
    path: 'call-for-papers',
    data: { title: 'SATNAC | Call For Papers' },
    loadChildren: () =>
      import('./page/call-for-papers/call-for-papers.module').then(
        (m) => m.CallForPapersModule
      ),
  },
  {
    path: 'sponsorship',
    data: { title: 'SATNAC | Sponsorship' },
    loadChildren: () =>
      import('./page/sponsorship/sponsorship.module').then(
        (m) => m.SponsorshipModule
      ),
  },
  {
    path: 'challenge',
    data: { title: 'SATNAC | Industry Challenge' },
    loadChildren: () =>
      import('./page/challenge/challenge.module').then(
        (m) => m.ChallengeModule
      ),
  },
  {
    path: 'past-challenge-2023',
    data: { title: 'SATNAC | Past Industry Challenge (2023)' },
    loadChildren: () =>
      import('./page/past-challenge/2023/past-challenge-2023.module').then(
        (m) => m.PastChallenge2023Module
      ),
  },
  {
    path: 'contact',
    data: { title: 'SATNAC | Contact' },
    loadChildren: () =>
      import('./page/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'proceedings',
    data: { title: 'SATNAC | Proceedings' },
    loadChildren: () =>
      import('./page/page-proceedings/page-proceedings.module').then(
        (m) => m.PageProceedingsModule
      ),
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledNonBlocking',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
